@import "../../../../../colors";

.account {
  &-subscription {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-main {
      display: flex;

      .sharp {
        margin-left: -5px;
      }
    }

    &-name {
      color: $type-main;
      font-size: 16px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-left: 4px;
    }

    &-caption {
      color: $secondary;
      font-size: 14px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin-left: 20px;
      margin-top: 5px;
    }
  }
}
