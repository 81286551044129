.checkout {
  width: 980px;
  max-height: 640px;

  &-header {
    z-index: 1001;
    position: absolute;
    display: flex;
    width: 980px;
    height: 50px;
    padding: 10px 0;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    button {
      margin-right: 10px;
      outline: none;
      border: none;

      &:hover {
        background: transparent;
      }
    }
  }
}
