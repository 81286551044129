@import "../../../../../colors";

.term {
  padding: 50px;

  &-name {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: $secondary;
  }

  &-billing-plan {
    margin-top: 12px;

    :first-child {
      font-family: Roboto, sans-serif;
      font-size: 35px;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0;
      text-align: left;
      color: $type-main;
    }

    :last-child {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
      color: $secondary;
    }

    &--free {
      opacity: 0;
    }
  }

  &-summary {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    color: $type-main;

    :first-child {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
    }

    :last-child {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    margin-top: 334px;

    .stripe {
      :first-child {
        font-family: Open Sans, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: $secondary;
      }

      :last-child {
        margin-left: 5px;
      }
    }

    .divider {
      height: 20px;
      width: 1px;
      border-left: 1px solid $divider-dark;
      margin: 0 20px;
    }

    &-link {
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: $secondary;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
