@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$secondary: #141B24CC;
$input-placeholder-color: #141B2466;
$divider-light: #141B240D;
$divider-dark: #141B241A;
$type-main: #0A0E12;
$background-main-1: #fff;
$background-level-3: #F3F4F5;

$theme-main: #E47A2E;

//$theme-colors: (
//        "secondary": #141B24CC
//);


@import "bootstrap/scss/bootstrap.scss";
