@import "../../../../../colors";

.apps {
  width: 650px;

  .col-md-10, .col-md-2 {
    padding-left: 0;
    padding-right: 0;
  }
}

.app {

  &-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    opacity: .7;
    min-width: 300px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-management-bar {
    padding: 15px 15px 0 30px;
  }

  &-management {
    padding: 15px 15px 15px 30px;
  }
}

.app-list-row {
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background: $background-level-3;

    .button-link {
      background: $background-level-3;
    }
  }
}

//.form {
//  display: flex;
//  flex-direction: column;
//
//  &-label {
//    font-family: Roboto;
//    font-size: 13px;
//    font-weight: 400;
//    line-height: 20px;
//    letter-spacing: 0;
//    text-align: left;
//    color: $secondary;
//    margin-bottom: 0;
//  }
//
//  &-control, &-select {
//    font-family: Roboto;
//    font-size: 13px;
//    font-weight: 400;
//    line-height: 20px;
//    letter-spacing: 0;
//    text-align: left;
//    box-shadow: 0 3px 6px 0 #00000008;
//    border: 1px solid $divider-dark;
//
//    &::placeholder {
//      color: $input-placeholder-color;
//    }
//  }
//
//}
