@import "../../../../../colors";

.account {
  &-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px;

    &-title {
      color: $type-main;
      font-size: 16px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &-toggle {
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
