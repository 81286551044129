@import "../../../colors";

.account {
  width: 1180px;
  max-height: 650px;
  min-height: 650px;

  .row {
    margin: 0;
  }

  &-subscriptions {
    padding: 0 30px;

    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 50%;

      color: $secondary;
      font-size: 16px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      .sharp {
        margin-bottom: 20px;
      }
    }
  }
}
