@import "../../../../../colors";

.modal {
  &-custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 18px 0 30px;

    &-title {
      color: $type-main;
      font-size: 25px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    button {
      outline: none;
      border: none;

      &:hover {
        background: transparent;
      }
    }
  }
}
