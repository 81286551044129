.modal-body {
  padding: 0 !important;

  .col {
    padding: 0 !important;
  }
}

.external-modal {
  min-width: fit-content !important;
  width: 100% !important;
}
