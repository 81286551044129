@import "../../../../../colors";

$submit-bg: #0074D4;

.bill {
  box-shadow: 0 15px 40px 0 $divider-dark;
  width: 100%;
  height: 100%;

  &-container {
    padding: 50px 70px;
  }

  .gpay {
    width: 350px;
    height: 40px;
    border-radius: 6px;
    background: $type-main;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: .65;
    }
  }

  .pay-with-card {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      min-width: 95px;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
      margin: 0 10px;
      color: $input-placeholder-color;
    }

    .divider {
      height: 1px;
      border-bottom: 1px solid $divider-light;
      width: 100%;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    &-label {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: $secondary;
      margin-bottom: 0;
    }

    &-control, &-select {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      box-shadow: 0 3px 6px 0 #00000008;
      border: 1px solid $divider-dark;

      &::placeholder {
        color: $input-placeholder-color;
      }
    }

    &-submit {
      display: flex;
      width: 350px;
      padding: 13px 0 12px 0;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: $submit-bg;
      color: $background-main-1;
      font-size: 13px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      outline: none;
      border: none;
      margin-top: 10px;

      &:hover {
        background: $submit-bg;
      }
    }
  }

  .card {
    &-row {
      padding: 0;
      margin: 0;
    }

    &-number {
      margin: 0;
      background-image: url("/assets/images/banks.svg");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      padding-right: calc(1.5em + 0.75rem);
      border-radius: 6px 6px 0 0;
    }

    &-date {
      border-radius: 0 0 0 6px;
      border-top: none;
    }

    &-cvc {
      border-radius: 0 0 6px 0;
      border-left: none;
      border-top: none;
    }
  }

  .country {
    &-select {
      border-radius: 6px 6px 0 0;
    }

    &-zip {
      border-radius: 0 0 6px 6px;
      border-top: none;
    }
  }


}
