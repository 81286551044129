@import "./colors.scss";

.slick {
  &-track {
    @extend .row;
  }

  &-slide {
    @extend .col-md-6;
  }

  &-arrow {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: #353535;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background: #ffffff;
}

.dropdown-toggle, .button-link {
  min-width: 100px;
  width: fit-content;
  border: none !important;
  color: $theme-main !important;
  padding: 0;
  font-size: 16px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  outline: none !important;
  text-decoration: none !important;

  &:focus, &:first-child:active, &:active, &:hover {
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;
    color: $theme-main !important;
  }

  .btn.show:focus-visible {
    box-shadow: none !important;
  }
}

.dropdown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-toggle {
    text-align: end;

    &::after {
      content: none !important;
    }

    &:focus-visible {
      box-shadow: none !important;
    }
  }

  &-menu {
    border: none;
    box-shadow: 0 15px 40px 0 rgba(20, 27, 36, 0.10);
  }

  &-item {
    color: $type-main;
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:active {
      color: $type-main;
      background-color: transparent;
    }

    &:hover {
      color: $type-main;
      background-color: $background-level-3;
    }
  }
}

#for-offer {
  margin: 0 15px 30px 0;
}

button {
  color: #E47A2E;
  transition: 0.5s;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  text-align: left;
}

a {
  color: #E47A2E;
  transition: 0.5s;

  &:hover {
    color: #353535;
    outline: none;
    text-decoration: none;
  }

  &:active {
    color: #353535;
    outline: none;
    text-decoration: none;
  }

  &:focus {
    color: #353535;
    outline: none;
    text-decoration: none;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  color: #353535;
  margin: 0 0 15px 0;
  padding: 0;
}

.back-to-top {
  position: fixed;
  background: #353535;
  color: #ffffff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  right: 15px;
  bottom: 15px;
  transition: opacity 0.2s;
  z-index: 11;
  opacity: 0;

  &--show {
    opacity: 1;
  }

  &:hover {
    background: #E47A2E;
  }

  i {
    color: #ffffff;
  }
}

.form-control {
  width: 100%;
  height: 35px;
  padding: 0 15px;
  color: #666666;
  border: 1px solid #353535;
  border-radius: 0;
  margin-bottom: 15px;

  &:focus {
    box-shadow: none;
    border-color: #E47A2E;
  }
}

.custom-select {
  width: 100%;
  height: 35px;
  padding: 0 15px;
  color: #666666;
  border: 1px solid #353535;
  border-radius: 0;
  margin-bottom: 15px;

  &:focus {
    box-shadow: none;
    border-color: #E47A2E;
  }
}

.custom-radio {
  margin-bottom: 15px;

  .custom-control-label {
    &::before {
      border-color: #353535;
      border-radius: 0;
    }
  }

  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: #ffffff;
            background: #E47A2E;
          }
        }
      }
    }
  }
}

.custom-checkbox {
  margin-bottom: 15px;

  .custom-control-label {
    &::before {
      border-color: #353535;
      border-radius: 0;
    }
  }

  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: #ffffff;
            background: #E47A2E;
          }
        }
      }
    }
  }
}

button.btn {
  color: #353535;
  background: #ffffff;
  border: 1px solid #353535;
  border-radius: 0;

  &:hover {
    color: #ffffff;
    background: #E47A2E;
    border-color: #E47A2E;
  }
}

.top-header {
  padding: 15px 0;
  background: #ffffff;

  .logo {
    text-align: left;
    overflow: hidden;

    button.link {
      img {
        max-width: 100%;
        max-height: 60px;
      }
    }
  }

  .search {
    width: 100%;

    input[type=text] {
      width: 100%;
      height: 40px;
      padding: 0 15px;
      color: #666666;
      border: 1px solid #353535;

      &:focus {
        border-color: #E47A2E;
      }
    }

    button {
      position: absolute;
      width: 40px;
      height: 38px;
      top: 1px;
      right: 16px;
      padding: 0 15px;
      border: none;
      background: none;
      color: #353535;

      &:hover {
        background: #E47A2E;
        color: #ffffff;
      }
    }
  }

  .social {
    position: relative;
    width: 100%;
    height: 40px;
    overflow: hidden;
    text-align: right;

    button.link {
      background-color: transparent;
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 1px 0;
      text-align: center;
      font-size: 22px;
      border: 1px solid #353535;

      &:focus {
        outline: none;
      }

      &:hover {
        background: #E47A2E;
        border: 1px solid #E47A2E;

        i {
          color: #ffffff;
        }
      }

      i {
        color: #353535;
      }
    }
  }
}

.header {
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);

  .navbar {
    height: 100%;
    padding: 0;
  }

  .dropdown-menu {
    margin-top: 0;
    border: 0;
    border-radius: 0;
    background: #f8f9fa;
  }
}

.header.header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      padding: 10px 10px 8px 10px;
      color: #ffffff;

      &:focus {
        padding: 10px 10px 8px 10px;
        color: #ffffff;
      }

      &:hover {
        padding: 10px 10px 8px 10px;
        color: #ffffff;
        background: rgba(228, 122, 46, .2);
        border-bottom: 2px solid #E47A2E;
        transition: none;
      }
    }

    .nav-link.active {
      padding: 10px 10px 8px 10px;
      color: #ffffff;
      background: rgba(228, 122, 46, .2);
      border-bottom: 2px solid #E47A2E;
      transition: none;
    }
  }
}

.top-news {
  position: relative;
  width: 100%;
  padding: 30px 0 0 0;

  .col-md-6 {
    margin-bottom: 30px;
  }

  .tn-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    &:hover {
      img {
        filter: blur(5px);
        -webkit-filter: blur(5px);
      }
    }
  }

  .tn-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px 10px 15px 20px;
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .3), rgba(0, 0, 0, .9));
    display: flex;
    align-items: flex-end;

    a.tn-date {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      transition: .3s;
      letter-spacing: 1px;
      text-align: left;
      padding-left: 0;
      text-decoration: none;

      i {
        margin-right: 5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    a.tn-title {
      display: block;
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      transition: .3s;
      text-decoration: none;
      text-align: left;
      padding: 0;

      &:hover {
        color: #E47A2E;
      }
    }
  }

  .tn-left {
    .tn-content {
      a.tn-title {
        font-size: 35px;
        font-weight: 700;
      }
    }
  }
}

.cat-news {
  position: relative;
  width: 100%;
  padding: 0;

  .col-md-6 {
    margin-bottom: 30px;
  }

  h2 {
    color: #353535;
    font-size: 35px;
    font-weight: 700;
    margin-left: 40px;
    margin-bottom: 15px;

    i {
      position: absolute;
      top: 8px;
      left: 15px;
      font-size: 28px;
    }
  }
  .cn-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
    &:hover {
      img {
        filter: blur(5px);
        -webkit-filter: blur(5px);
      }
    }
  }
  .cn-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px 10px 15px 20px;
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .3), rgba(0, 0, 0, .9));
    display: flex;
    align-items: flex-end;
    button.cn-date {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      transition: .3s;
      letter-spacing: 1px;
      i {
        margin-right: 5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    button.cn-title {
      display: block;
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      transition: .3s;
      &:hover {
        color: #E47A2E;
      }
    }
  }
  .slick-prev {
    top: -35px;
    width: 28px;
    height: 28px;
    z-index: 1;
    transition: .5s;
    color: #ffffff;
    background: #353535;
    left: calc(100% - 85px);
    text-align: center;

    &:hover {
      color: #ffffff;
      background: #E47A2E;
    }
    &:focus {
      color: #ffffff;
      background: #E47A2E;
    }
    &::before {
      font-family: "Font Awesome 5 Free";
      font-size: 18px;
      font-weight: 900;
      color: #ffffff;
      content: "\f104";
    }
  }
  .slick-next {
    top: -35px;
    width: 28px;
    height: 28px;
    z-index: 1;
    transition: .5s;
    color: #ffffff;
    background: #353535;
    right: 14px;
    text-align: center;
    &:hover {
      color: #ffffff;
      background: #E47A2E;
    }
    &:focus {
      color: #ffffff;
      background: #E47A2E;
    }
    &::before {
      font-family: "Font Awesome 5 Free";
      font-size: 18px;
      font-weight: 900;
      color: #ffffff;
      content: "\f105";
    }
  }
}
.main-news {
  position: relative;
  width: 100%;
  padding: 0;
  .col-md-6 {
    margin-bottom: 30px;
  }
  .col-md-12 {
    margin-bottom: 30px;
  }
  h2 {
    color: #353535;
    font-size: 35px;
    font-weight: 700;
    margin-left: 40px;
    i {
      position: absolute;
      top: 8px;
      left: 15px;
      font-size: 28px;
    }
  }
  .mn-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .mn-content {
    position: relative;
    width: 100%;
    padding: 15px 0 0 0;
    button.mn-title {
      display: block;
      width: 100%;
      color: #353535;
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 10px;
      &:hover {
        color: #E47A2E;
      }
    }
    button.mn-date {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: #757575;
      font-size: 14px;
      font-weight: 400;
      transition: .3s;
      letter-spacing: 1px;
      i {
        margin-right: 5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin: 0;
    }
  }
  .mn-list {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 30px;
    .mn-img {
      position: relative;
      width: 100px;
      margin-right: 15px;
      float: left;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .mn-content {
      position: relative;
      float: left;
      width: calc(100% - 115px);
      padding: 0;
      button.mn-title {
        display: block;
        width: 100%;
        color: #353535;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 5px;
        &:hover {
          color: #E47A2E;
        }
      }
      button.mn-date {
        display: block;
        width: 100%;
        margin-bottom: 0;
        color: #757575;
        font-size: 14px;
        font-weight: 400;
        transition: .3s;
        letter-spacing: 1px;
        i {
          margin-right: 5px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.sidebar {
  position: relative;
  width: 100%;
  .sidebar-widget {
    position: relative;
    width: 100%;
    margin-bottom: 45px;
    h2 {
      color: #353535;
      font-size: 35px;
      font-weight: 700;
      margin-left: 40px;
      i {
        position: absolute;
        top: 8px;
        left: 0;
        font-size: 28px;
      }
    }
    .category {
      position: relative;
      .fa-ul {
        margin-left: 23px;
        li {
          margin-bottom: 10px;
          i {
            color: #353535;
          }
          button {
            color: #353535;
            &:hover {
              color: #E47A2E;
            }
          }
        }
      }
    }
    .tags {
      position: relative;
      button.link {
        display: inline-block;
        margin-bottom: 5px;
        padding: 5px 10px;
        color: #353535;
        background: #ffffff;
        border: 1px solid #353535;
        &:hover {
          color: #ffffff;
          background: #E47A2E;
          border: 1px solid #E47A2E;
        }
      }
    }
    .image {
      a {
        display: block;
        width: 100%;
        overflow: hidden;
      }
      img {
        max-width: 100%;
        transition: .3s;
        &:hover {
          transform: scale(1.1);
          filter: blur(3px);
          -webkit-filter: blur(3px);
        }
      }
    }
  }
}
.breadcrumb-wrap {
  position: relative;
  width: 100%;
  background: #ffffff;
  .breadcrumb {
    margin: 25px 0 0 0;
    padding: 0;
    background: none;
    .breadcrumb-item {
      + {
        .breadcrumb-item {
          &::before {
            color: #353535;
          }
        }
      }
    }
    .breadcrumb-item.active {
      color: #353535;
    }
  }
}
.single-news {
  position: relative;
  width: 100%;
  padding: 25px 0 0 0;
  .sn-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .sn-content {
    position: relative;
    width: 100%;
    padding: 30px 0 30px 0;
    a.sn-title {
      display: block;
      width: 100%;
      color: #353535;
      font-size: 35px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 10px;
      &:hover {
        color: #E47A2E;
      }
    }
    a.sn-date {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      color: #757575;
      font-size: 14px;
      font-weight: 400;
      transition: .3s;
      letter-spacing: 1px;
      i {
        margin-right: 5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.contact {
  position: relative;
  padding: 25px 0 45px 0;
  .map {
    position: relative;
    width: 100%;
    iframe {
      width: 100%;
      height: 300px;
    }
  }
  .contact-info {
    h4 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      i {
        color: #353535;
        margin-right: 5px;
      }
    }
  }
  .social {
    position: relative;
    width: 100%;
    a {
      display: inline-block;
      margin: 10px 10px 0 0;
      width: 40px;
      height: 40px;
      padding: 3px 0;
      text-align: center;
      font-size: 20px;
      border: 1px solid #353535;
      i {
        color: #353535;
      }
      &:hover {
        background: #E47A2E;
        border-color: #E47A2E;
        i {
          color: #ffffff;
        }
      }
    }
  }
  .form {
    color: #666666;
  }
}
.footer {
  position: relative;
  padding: 60px 0 0 0;
  margin-top: 15px;
  background: #353535;
  .footer-widget {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    h1 {
      color: #ffffff;
      margin-bottom: 18px;
    }
    .title {
      color: #ffffff;
      white-space: nowrap;
    }
    p {
      color: #ffffff;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        button {
          color: #ffffff;
          white-space: nowrap;
          display: block;
          line-height: 23px;
          &::before {
            content: '\f061';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            padding-right: 5px;
          }
          &:hover {
            padding-left: 10px;
          }
        }
      }
    }
  }
  .contact-info {
    p {
      margin-bottom: 5px;
      color: #ffffff;
      font-size: 16px;
      i {
        color: #ffffff;
        margin-right: 5px;
      }
    }
  }
  .social {
    position: relative;
    width: 100%;
    button.link {
      display: inline-block;
      margin: 10px 5px 0 0;
      width: 35px;
      height: 35px;
      padding: 2px 0;
      text-align: center;
      font-size: 18px;
      background: #353535;
      border: 1px solid #ffffff;
      i {
        color: #ffffff;
      }
      &:hover {
        background: #E47A2E;
        border: 1px solid #E47A2E;
      }
    }
  }
  .newsletter {
    position: relative;
    width: 100%;
    form {
      position: relative;
      width: 100%;
    }
    input {
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #ffffff;
      &:focus {
        box-shadow: none;
      }
    }
    .btn {
      position: absolute;
      width: 80px;
      height: 40px;
      top: 0;
      right: 0;
      padding: 0 15px;
      border: none;
      background: #353535;
      color: #ffffff;
      border: 1px solid #ffffff;
      &:hover {
        background: #E47A2E;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.footer-bottom {
  position: relative;
  padding: 25px 0;
  background: #111111;
  .copyright {
    text-align: left;
    p {
      color: #ffffff;
      font-weight: 400;
      margin: 0;
      a {
        font-weight: 600;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  .template-by {
    text-align: right;
    p {
      color: #ffffff;
      font-weight: 400;
      margin: 0;
      a {
        font-weight: 600;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .top-header {
    max-height: 90px;
  }
  .header {
    background: #353535 !important;
    .navbar {
      background: #353535 !important;
    }
    .navbar-brand {
      display: none;
    }
    a.nav-link {
      padding: 8px 15px;
      font-size: 15px;
      text-transform: uppercase;
    }
  }
}
@media (max-width: 767.98px) {
  .top-header {
    .logo {
      text-align: center;
      margin-bottom: 15px;
    }
    .search {
      text-align: center;
      margin-bottom: 15px;
    }
    .social {
      text-align: center;
      margin-bottom: 15px;
      margin-bottom: 0;
    }
  }
  .contact {
    .form {
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 768px) {
  .header {
    background: #333333 !important;
    .navbar {
      background: #333333 !important;
    }
    a.nav-link {
      padding: 5px;
    }
    .dropdown-menu {
      box-shadow: none;
    }
  }
}
@media (min-width: 992px) {
  .footer {
    .container-fluid {
      max-width: 95%;
    }
  }
}
@media (max-width: 768.98px) {
  .footer-bottom {
    .copyright {
      text-align: center;
    }
    .template-by {
      text-align: center;
    }
  }
}

.notification {
  z-index: 100001;
  position: fixed !important;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 450px;
}
